import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PracticeService {
  public apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getOrgs(appName='AR'): Observable<any[]> {
    // this.apiUrl =  environment.apiUrl;
    return this.http.post<any>(this.apiUrl + '/dashboard/getorgs',{ data: appName })
    .pipe(map((res) => {
      return res;
    }));
  }

  getFields(payload: any): Observable<any[]> {
    // this.apiUrl =  environment.apiUrl;
    return this.http.post<any>(this.apiUrl + '/dashboard/getcustomfields',{ data: payload })
    .pipe(map((res) => {
      return res;
    }));
  }

  getRecurr(payload: any): Observable<any[]> {
    // this.apiUrl =  environment.apiUrl;
    return this.http.post<any>(this.apiUrl + '/dashboard/getrecurr',{ data: payload })
    .pipe(map((res) => {
      return res;
    }));
  }

  getTasks(payload: any): Observable<any[]> {
    // this.apiUrl =  environment.apiUrl;
    return this.http.post<any>(this.apiUrl + '/dashboard/gettasks',{ data: payload })
    .pipe(map((res) => {
      return res;
    }));
  }

  extendTrial(data: any){
    return this.http.post<any>(this.apiUrl + '/extend_trial', { data: data })
      .pipe(map((res) => {
        return res;
      }));
  }

  updateUrl(data: any){
    return this.http.post<any>(this.apiUrl + '/dashboard/updatePracticeUrl', { data: data })
      .pipe(map((res) => {
        return res;
      }));
  }

  updatePromo(data:any){
    return this.http.post<any>(this.apiUrl + '/update_promocode', { data: data })
      .pipe(map((res) => {
        return res;
      }));
  }

  updateStatus(data:any){
    return this.http.post<any>(this.apiUrl + '/updatestatus', { data: data })
      .pipe(map((res) => {
        return res;
      }));
  }

  deletePractice(data:any){
    return this.http.post<any>(this.apiUrl + '/deletepractice', { data: data })
      .pipe(map((res) => {
        return res;
      }));
  }

}