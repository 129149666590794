import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, debounceTime, distinctUntilChanged, of, pipe, switchMap, tap } from 'rxjs';
import { PracticeService } from '../../services/practices.service';
import { SharedService } from '../../services/shared.service';
import { update } from 'lodash';

export interface PracticeState {
    orgLoader: boolean;
    orgData: any;
    fieldsData: any;
    recurrData: any;
    taskData: any;
    promocode: any;
    orgCount: number
}
const initialPracticeState: PracticeState = {
    orgLoader: false,
    orgData: [],
    fieldsData: [],
    recurrData: [],
    taskData: [],
    promocode: [],
    orgCount: 0
};
export const PracticeStore = signalStore(
  withState<PracticeState>(initialPracticeState),
  withMethods(({...store},practices = inject(PracticeService), sharedService = inject(SharedService)) => ({
    loadPractices: rxMethod<void>(
pipe(
  debounceTime(300),
  distinctUntilChanged(),
  switchMap(()=> {
    
    return practices.getOrgs().pipe(
      tap((orgs: any) => {
        patchState(store, { orgData: orgs })
        return orgs;
      }))
  })
)
    ),
    extendTrial: rxMethod<any>(
        pipe(
            tap(() => patchState(store)),
            switchMap((payload: any) => {
                return practices.extendTrial(payload).pipe(
                    tap({
                        next: (data: any) => {
                            if (data?.success) {
                                sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                var updatedArray = store.orgData().map(function(obj: any) {
                                    if (obj._id === data.orgId) {
                                    return { ...obj, end_date: data.extend_date }; // Create a new object with updated age
                                    }
                                    return obj; // Return the original object if no update is needed
                                });
                                patchState(store, { orgData: [...updatedArray] });
                            }
                        },
                        error: console.error,
                        finalize: () => patchState(store),
                    }),
                    catchError((error) => {
                        patchState(store)
                        return of(null);
                    })
                )
            })
        ),
    ),
    updateUrl: rxMethod<any>(
        pipe(
            tap(() => patchState(store)),
            switchMap((payload: any) => {
                return practices.updateUrl(payload).pipe(
                    tap({
                        next: (data: any) => {
                            if (data?.success) {
                                sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                
                                var updatedArray = store.orgData().map(function(obj: any) {
                                    if (obj._id === data.orgId) {
                                    return { ...obj, url: data.url }; // Create a new object with updated age
                                    }
                                    return obj; // Return the original object if no update is needed
                                });
                                patchState(store, { orgData: [...updatedArray] });
                            }
                        },
                        error: console.error,
                        finalize: () => patchState(store),
                    }),
                    catchError((error) => {
                        patchState(store)
                        return of(null);
                    })
                )
            })
        ),
    ),
    updatePromo: rxMethod<any>(
        pipe(
            tap(() => patchState(store)),
            switchMap((payload: any) => {
                return practices.updatePromo(payload).pipe(
                    tap({
                        next: (data: any) => {
                            if (data?.success) {
                                sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                var updatedArray = store.orgData().map(function(obj: any) {
                                    if (obj._id === data.orgId) {
                                    return { ...obj, promoCode: data.promoCode }; // Create a new object with updated age
                                    }
                                    return obj; // Return the original object if no update is needed
                                });
                                patchState(store, { orgData: [...updatedArray] });
                            }
                        },
                        error: console.error,
                        finalize: () => patchState(store),
                    }),
                    catchError((error) => {
                        patchState(store)
                        return of(null);
                    })
                )
            })
        ),
    ),
    activatePractice: rxMethod<any>(
        pipe(
            tap(() => patchState(store)),
            switchMap((payload: any) => {
                return practices.updateStatus(payload).pipe(
                    tap({
                        next: (data: any) => {
                            if (data?.success) {
                                sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                var updatedArray = store.orgData().map(function(obj: any) {
                                    if (obj._id === data.orgId) {
                                        console.log(obj);
                                        if(data.isCancelled)
                                        return { ...obj, isCancelled: data.isCancelled, end_date: data.end_date, cancelledDate: data.cancelledDate }; // Create a new object with updated age
                                        else
                                        return { ...obj, isCancelled: data.isCancelled, end_date: data.end_date }; // Create a new object with updated age
                                    }else{
                                        return obj;
                                    }
                                });
                                console.log(updatedArray);
                                patchState(store, { orgData: [...updatedArray] });
                            }
                        },
                        error: console.error,
                        finalize: () => patchState(store),
                    }),
                    catchError((error) => {
                        patchState(store)
                        return of(null);
                    })
                )
            })
        ),
    ),
    deletePractice: rxMethod<any>(
        pipe(
            switchMap((payload: any) => {
                return practices.deletePractice(payload).pipe(
                    tap({
                        next: (data: any) => {
                            if (data?.isSuccess) {
                                sharedService.messageService('Deleted Successfully!', 5000, 'center', 'top');
                                const orgIndex = store.orgData().findIndex((x:any) => x._id === payload.orgId);
                                const updateArray = [...store.orgData()];
                                updateArray.splice(orgIndex,1);
                                patchState(store, {orgData: updateArray});
                                
                            }
                        },
                        error:(err)=>{
                            console.error(err);
                            sharedService.messageService('Error deleting practice!', 5000, 'center', 'top');
                        } 
                    }),
                    catchError((error) => {
                        console.error(error);
                        patchState(store)
                        return of(null);
                    })
                )
            })
        ),
    ),
    loadFields: rxMethod<any>(
        pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((payload: any)=> {
              
              return practices.getFields(payload).pipe(
                tap((fields: any) => {
                  patchState(store, { fieldsData: fields })
                  return fields;
                }))
            })
    )),
    loadRecurr: rxMethod<any>(
        pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((payload: any)=> {
              
              return practices.getRecurr(payload).pipe(
                tap((fields: any) => {
                  patchState(store, { recurrData: fields })
                  return fields;
                }))
            })
    )),
    loadTasks: rxMethod<any>(
        pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((payload: any)=> {
              
              return practices.getTasks(payload).pipe(
                tap((fields: any) => {
                  patchState(store, { taskData: fields })
                  return fields;
                }))
            })
    )),
    resetFieldState(){
        return patchState(store,{fieldsData : []})
    },
    resetAccountState() {
      return patchState(store, initialPracticeState);
    },
  }))
);
