import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  public apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getCompanies(appName='AR') {
    // this.apiUrl =  environment.apiUrl;
    return this.http.post<any>(this.apiUrl + '/dashboard/getcompanies',{ data: appName })
      .pipe(
        map(orgs => {
            return orgs;
        })
      );
  }

  getDeletedCompanies(appName='AR') {
    // this.apiUrl =  environment.apiUrl;
    return this.http.post<any>(this.apiUrl + '/dashboard/getDeletedClientFile',{ data: appName })
      .pipe(
        map(comp => {
            return comp;
        })
      );
  }

  syncTrans(data: any){
    return this.http.post<any>(this.apiUrl + '/company/synctrans', { data: data })
      .pipe(map((synctrans) => {
        return synctrans;
      }));
  }

  updateSyncDate(data:any){
    return this.http.post<any>(this.apiUrl + '/dashboard/updateSyncDate',{data: data})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}