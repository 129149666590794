import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public snackBar: MatSnackBar) { }

  // for success message of snackBar
  messageService(msg: string, duration: number, horizontalPosition:any, verticalPosition:any) {
    this.snackBar.open(msg, '', {
      duration: duration,
      panelClass: ['mycsssnackbartest'],
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }
}