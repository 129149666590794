import { inject } from '@angular/core';
import { patchState, signalStore,withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { debounceTime, distinctUntilChanged, pipe, switchMap, tap } from 'rxjs';
import { InvoiceService } from '../../services/invoices.service';

export interface InvoiceState {
    invLoader: boolean;
    invData: any;
    invCount: number
}
const initialInvoiceState: InvoiceState = {
    invLoader: false,
    invData: [],
    invCount: 0
};
export const InvoiceStore = signalStore(
  withState<InvoiceState>(initialInvoiceState),
  withMethods(({...store},invoices = inject(InvoiceService)) => ({
    loadInvoives: rxMethod<void>(
pipe(
  debounceTime(300),
  distinctUntilChanged(),
  switchMap(()=> {
    
    return invoices.getInvoices().pipe(
      tap((invoices: any) => {
        patchState(store, { invData: invoices })
        return invoices;
      }))
  })
)
    ),
    resetAccountState() {
      return patchState(store, initialInvoiceState);
    },
  }))
);
