import { inject } from '@angular/core';
import { patchState, signalStore,withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, debounceTime, distinctUntilChanged, of, pipe, switchMap, tap } from 'rxjs';
import { UserService } from '../../services/users.service';
import { SharedService } from '../../services/shared.service';

export interface UserState {
    userLoader: boolean;
    userData: any;
    userCount: number;
}
const initialUserState: UserState = {
    userLoader: false,
    userData: [],
    userCount: 0
};
export const UserStore = signalStore(
  withState<UserState>(initialUserState),
  withMethods(({...store},users = inject(UserService),sharedService = inject(SharedService)) => ({
    loadUsers: rxMethod<void>(
pipe(
  debounceTime(300),
  distinctUntilChanged(),
  switchMap(()=> {
    
    return users.getUsers().pipe(
      tap((users: any) => {
        patchState(store, { userData: users })
        return users;
      }))
  })
)
    ),
    updateMobile: rxMethod<any>(
        pipe(
            tap(() => patchState(store)),
            switchMap((payload: any) => {
                console.log(payload);
                return users.updateMobile(payload).pipe(
                    tap({
                        next: (data: any) => {
                            console.log(data);
                            if (data?.success) {
                                sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                var updatedArray = store.userData().map(function(obj: any) {
                                    if (obj._id === data.userId) {
                                    return { ...obj, mCountryCode: data.mCountryCode, mobileNo: data.mobileNo }; // Create a new object with updated age
                                    }
                                    return obj; // Return the original object if no update is needed
                                });
                                patchState(store, { userData: [...updatedArray] });
                            }
                        },
                        error: console.error,
                        finalize: () => patchState(store),
                    }),
                    catchError((error) => {
                        patchState(store)
                        return of(null);
                    })
                )
            })
        ),
    ),
    updatePassword: rxMethod<any>(
        pipe(
            tap(() => patchState(store)),
            switchMap((payload: any) => {
                console.log(payload);
                return users.updatePassword(payload).pipe(
                    tap({
                        next: (data: any) => {
                            console.log(data);
                            if (data?.success) {
                                sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                // var updatedArray = store.userData().map(function(obj: any) {
                                //     if (obj._id === data.userId) {
                                //     return { ...obj, mCountryCode: data.mCountryCode, mobileNo: data.mobileNo }; // Create a new object with updated age
                                //     }
                                //     return obj; // Return the original object if no update is needed
                                // });
                                //patchState(store, { userData: [...updatedArray] });
                            }
                        },
                        error: console.error,
                        finalize: () => patchState(store),
                    }),
                    catchError((error) => {
                        patchState(store)
                        return of(null);
                    })
                )
            })
        ),
    ),
    verifyUser: rxMethod<any>(
        pipe(
            tap(() => patchState(store)),
            switchMap((payload: any) => {
                console.log(payload);
                return users.verifyUser(payload).pipe(
                    tap({
                        next: (data: any) => {
                            console.log(data);
                            if (data?.success) {
                                sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                var updatedArray = store.userData().map(function(obj: any) {
                                    if (obj._id === data.userId) {
                                    return { ...obj, isVerified: data.isVerified }; // Create a new object with updated age
                                    }
                                    return obj; // Return the original object if no update is needed
                                });
                                patchState(store, { userData: [...updatedArray] });
                            }
                        },
                        error: console.error,
                        finalize: () => patchState(store),
                    }),
                    catchError((error) => {
                        patchState(store)
                        return of(null);
                    })
                )
            })
        ),
    ),
    resetAccountState() {
      return patchState(store, initialUserState);
    },
  }))
);
