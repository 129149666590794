import { inject } from '@angular/core';
import { patchState, signalStore,withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, debounceTime, distinctUntilChanged, of, pipe, switchMap, tap } from 'rxjs';
import { CompanyService } from '../../services/companies.service';
import { SharedService } from '../../services/shared.service';

export interface CompanyState {
    compLoader: boolean;
    compData: any;
    compCount: number;
    delCompData: any;
    delCompCount: number
};
  
const initialCompanyState: CompanyState = {
    compLoader: false,
    compData: [],
    compCount: 0,
    delCompData: [],
    delCompCount: 0
};
export const CompanyStore = signalStore(
    withState<CompanyState>(initialCompanyState),
    withMethods(({...store},companies = inject(CompanyService), sharedService = inject(SharedService)) => ({
      loadCompanies: rxMethod<void>(
  pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(()=> {
      
      return companies.getCompanies().pipe(
        tap((comps: any) => {
          patchState(store, { compData: comps })
          return comps;
        }))
    })
  )
      ),
      loadDelCompanies: rxMethod<void>(
        pipe(
          debounceTime(300),
          distinctUntilChanged(),
          switchMap(()=> {
            
            return companies.getDeletedCompanies().pipe(
              tap((comps: any) => {
                patchState(store, { delCompData: comps })
                return comps;
              }))
          })
        )
      ),
      syncTrans: rxMethod<any>(
              pipe(
                  tap(() => patchState(store)),
                  switchMap((payload: any) => {
                    
                      return companies.syncTrans(payload).pipe(
                          tap({
                              next: (data: any) => {
                                sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                var updatedArray = store.compData().map(function(obj: any) {
                                    return obj; // Return the original object if no update is needed
                                });
                                patchState(store, { compData: [...updatedArray] });
                              },
                              error: console.error,
                              finalize: () => patchState(store),
                          }),
                          catchError((error) => {
                              patchState(store)
                              return of(null);
                          })
                      )
                  })
              ),
      ),
      updateSyncDate: rxMethod<any>(
              pipe(
                  tap(() => patchState(store)),
                  switchMap((payload: any) => {
                      
                      return companies.updateSyncDate(payload).pipe(
                          tap({
                              next: (data: any) => {
                                  
                                  if (data?.success) {
                                      sharedService.messageService('Updated Successfully!', 5000, 'center', 'top');
                                      var updatedArray = store.compData().map(function(obj: any) {
                                        if (obj._id === data.companyId) {
                                            
                                            return { ...obj, startSyncDate: data.startSyncDate }; // Create a new object with updated age
                                        }
                                        return obj; // Return the original object if no update is needed
                                      });
                                    
                                    patchState(store, { compData: [...updatedArray] });
                                  }
                              },
                              error: console.error,
                              finalize: () => patchState(store),
                          }),
                          catchError((error) => {
                              patchState(store)
                              return of(null);
                          })
                      )
                  })
              ),
      ),
      resetAccountState() {
        return patchState(store, initialCompanyState);
      },
    }))
  );